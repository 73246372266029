import cn from "classnames";
import React from "react";

import CheckIcon from "../CheckIcon/CheckIcon";
import DotsIcon from "../CheckIcon/DotsIcon";
import SliderModal from "shared/ui/modal/SliderModal/SliderModal";

import TicketAcceptedCircle from "../../../../../../images/TicketAcceptedCircle";
import TicketDeniedTriangle from "../../../../../../images/TicketDeniedTriangle";
import TicketSharedCircle from "../../../../../../images/TicketSharedCircle";

import styles from "./ModalContainer.module.scss";

export interface ModalContainerProps {
  isOpen: boolean;
  name?: string;
  onClose: () => void;
  justification?: string;
  children: React.ReactNode;
  statusColor?: "gray" | "green" | "blue" | "orange" | "violet";
  statusText?: string;
  customHeadline?: React.ReactNode;
  headlineClassName?: string;
  statusIcon?: "dots" | "check" | "triangle";
  isFixedHeadline?: boolean;
  isHideStatus?: boolean;
  isJustificationLeft?: boolean;
  contentClassName?: string;
}

function ModalContainer({
  children,
  isOpen,
  onClose,
  justification,
  name,
  statusColor = "green",
  statusText,
  customHeadline,
  headlineClassName,
  statusIcon,
  isFixedHeadline,
  isHideStatus,
  isJustificationLeft,
  contentClassName,
}: ModalContainerProps) {
  console.log(justification);
  return (
    <SliderModal
      isOpen={isOpen}
      closeHandler={onClose}
      className=""
      childrenContainerClassName={cn({ [styles.scrollableContainer]: isFixedHeadline })}
    >
      <div className={cn(styles.headline, headlineClassName, { [styles.fixedHeadline]: isFixedHeadline })}>
        <h2 className={styles.title}>{name}</h2>
        {justification && !statusText && (
          <div className={cn(styles.justification, { [styles.ml0]: isJustificationLeft })}>{justification}</div>
        )}
        {statusText && (
          <div className={styles.subheader}>
            {justification ? <div className={cn(styles.justification, styles.ml0)}>{justification}</div> : <div />}
            {!isHideStatus && (
              <div className={cn(styles.status, styles[statusColor])}>
                {statusIcon === "dots" && <TicketSharedCircle backgroundColor={"white"} signColor={"#E8527A"} />}
                {statusIcon === "check" && <TicketAcceptedCircle backgroundColor={"white"} signColor={"#00C1AB"} />}
                {statusIcon === "triangle" && <TicketDeniedTriangle backgroundColor={"white"} signColor={"#E8527A"} />}
                {!statusIcon && (statusColor !== "orange" ? <CheckIcon /> : <DotsIcon />)}
                {statusText}
              </div>
            )}
          </div>
        )}
        {customHeadline}
      </div>
      <div className={cn(styles.content, contentClassName)}>{children}</div>
    </SliderModal>
  );
}

export default React.memo(ModalContainer);
