import { message } from "antd";
import Axios from "axios";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { compose } from "redux";

import { createBuilding } from "../../../../../redux/modules/common/building/objects";

import { errorCatcher } from "../../../../../utils/helpers/errorCatcher";

const defaultProjectEditFields = {
  name: "",
  nameErr: "",
  nameTouched: false,
  entity: null,
  entityErr: "",
  entityTouched: false,
};

export const useEditProject = (baseUrl) => {
  const urlId = useParams().id;
  const { projectId } = useParams();
  const isNew = urlId === "new";
  const history = useHistory();
  const dispatch = useDispatch();

  const [entitiesList, setEntitiesList] = React.useState(null);
  const [object, setObject] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const [localForm, setLocalForm] = React.useState(defaultProjectEditFields);

  const localFormHandler = (value, field) => {
    setLocalForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  React.useEffect(() => {
    Axios.get(`/entities/`)
      .then((resp) => setEntitiesList(resp.data.results))
      .catch((err) => console.error(err));
  }, [urlId]);

  const loadObject = React.useCallback(() => {
    return Axios.get(`/building/${urlId}/`)
      .then((resp) => {
        console.log("resp", resp.data);
        setObject(resp.data);
      })
      .catch(errorCatcher);
  }, [urlId]);

  React.useEffect(() => {
    if (!urlId) return;
    if (isNew) {
      try {
        const filledData = JSON.parse(window.sessionStorage.getItem("projectData") || "{}");
        setObject(filledData);
      } catch (e) {
        setObject(null);
      }
      return;
    }
    window.sessionStorage.setItem("projectData", "{}");
    setIsLoading(true);
    loadObject();
    setIsLoading(false);
  }, [urlId, isNew]);

  React.useEffect(() => {
    if (isNew || !object) return;
    object.name && localFormHandler(object.name, "name");
    object.entity?.id && localFormHandler(object.entity.id, "entity");
  }, [object, isNew]);

  const [isPending, setIsPending] = React.useState(false);

  const editPassportHandler = async ({ vals, urlId, dateStart, dateEnd }) => {
    if (!localForm.name) {
      message.error("Заполните наименование");
      return;
    }
    if (!localForm.entity) {
      message.error("Выберите организацию");
      return;
    }
    localFormHandler(false, "nameTouched");
    localFormHandler(false, "entityTouched");

    const values = {
      ...vals,
      construction_date_start: moment(dateStart).format("YYYY-MM-DD"),
      construction_date_end: moment(dateEnd).format("YYYY-MM-DD"),
      entity_id: localForm.entity,
      name: localForm.name,
      customer: vals.customer ?? "",
      general_contractor: vals.general_contractor ?? "",
      technical_supervision: vals.technical_supervision ?? "",
      developer: vals.developer ?? "",
      permit_build: vals.permit_build ?? "",
      new_close_dates: vals.new_close_dates ?? false,
      is_simple_parser: vals.is_simple_parser ?? false,
    };

    if (isNew) {
      const filledData = JSON.parse(window.sessionStorage.getItem("projectData") || "{}");
      const dataForCreating = { ...filledData, ...values };
      window.sessionStorage.setItem("projectData", JSON.stringify(dataForCreating));

      if (!dataForCreating.responsible_employee_id) {
        history.push("users");
        message.success("Выберите руководителя");
        return;
      }

      if (projectId) {
        values.project_id = projectId;
      }
      setIsPending(true);
      compose(
        dispatch,
        createBuilding
      )(values)
        .then((building) => history.replace(`${baseUrl}/${building?.id}/passport`))
        .finally(() => {
          setIsPending(false);
        });
      window.sessionStorage.setItem("projectData", "{}");
      return;
    }

    setIsLoading(true);
    setIsPending(true);
    Axios.patch(`/building/${urlId}/`, values)
      .then(() => {
        loadObject().then(() => setIsLoading(false));
        message.success("Проект успешно изменен");
      })
      .catch((e) => {
        errorCatcher(e);
        setIsLoading(false);
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  const resetForm = React.useCallback(() => {
    setLocalForm(isNew ? defaultProjectEditFields : (prev) => ({ name: object?.name, entity: object?.entity?.id }));
    loadObject();
  }, [isNew, object]);

  return {
    entitiesList,
    urlId,
    isNew,
    isLoading,
    object,
    editPassportHandler,
    localForm,
    localFormHandler,
    resetForm,
    isPending,
  };
};
