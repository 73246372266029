import _ from "lodash";

import { CHART_MAX_LRU_COLORS, CHART_STATUSES_DEFAULT_CONFIG } from "components/pages/Chart/constants";

import { IChartAction } from "./actions";

import {
  CHART_TABS,
  CHART_VIEW_MODE,
  ChartActions,
  ChartActionsType,
  ChartCheckpointsMarks,
  IChartFilters,
  IChartPersistValues,
  IChartState,
  IChartTree,
} from "./types";

import { getInitialTouchedYears, makeTreeId, patchRootTreeDatesDistance, updateTree } from "./utils";

const initDate = new Date();

const initChartTree = () => {
  const rootId = Math.random();
  // @ts-ignore
  const rootNode: IChartTree = {
    _id: makeTreeId(rootId, 0, "root"),
    id: rootId,
    lvl: 0,
    children: [],
    datesDistance: {
      start: null,
      end: null,
    }
  };
  return rootNode;
};

const initLoadedIntervalsByYears = () => ({
  [CHART_TABS.WORK]: {},
  [CHART_TABS.RESOURCES]: {},
  [CHART_TABS.MATERIALS]: {},
  [CHART_TABS.EQUIPMENT]: {},
  [CHART_TABS.MIM]: {},
});

const initTrees = () => ({
  [CHART_TABS.WORK]: initChartTree(),
  [CHART_TABS.RESOURCES]: initChartTree(),
  [CHART_TABS.MATERIALS]: initChartTree(),
  [CHART_TABS.EQUIPMENT]: initChartTree(),
  [CHART_TABS.MIM]: initChartTree(),
});

export const initChartTreeFilters = (): IChartFilters => ({
  dateStart: null,
  dateEnd: null,
  searchText: "",
});

export const initCheckpoinsMarks = (): ChartCheckpointsMarks => ({
  days: {},
  yearWeeks: {},
});

// здесь инициализируются только "системные" действия – те, для которых нет переключателя в UI
// остальные задаются через конфиг графика
export const initChartActions = (): ChartActionsType => ({
  [ChartActions.confirmed_highlight]: true,
  [ChartActions.moderation_highlight]: true,
  [ChartActions.canceled_highlight]: true,
  [ChartActions.linking_enabled]: true,
  [ChartActions.remarks_visible]: true,
  [ChartActions.out_of_estimate_enabled]: false,
  [ChartActions.default_tree_open_state]: undefined,
});

export const createChartInitialState: (prevState?: IChartState, persistValues?: IChartPersistValues) => IChartState = (
  prevState,
  persistValues
) => ({
  controller: new AbortController(),
  tab: prevState?.tab || null,
  hash: persistValues?.hash ? (prevState?.hash as number) : Math.random(),
  projectSliceIndex: 0,
  projectIds: persistValues?.projectIds ? prevState?.projectIds || [] : [],
  trees: initTrees(),
  scrollState: {
    year: prevState?.scrollState?.year ?? initDate.getFullYear(),
    month: prevState?.scrollState?.month ?? initDate.getMonth(),
    markers: {},
    touchedYears: getInitialTouchedYears({
      year: prevState?.scrollState?.year ?? initDate.getFullYear(),
      month: prevState?.scrollState?.month ?? initDate.getMonth(),
    }),
  },
  loadedIntervalsByYears: initLoadedIntervalsByYears(),
  viewMode: prevState?.viewMode || CHART_VIEW_MODE.DAYS_30,
  actions: prevState?.actions || initChartActions(),
  treeState: {
    cachedOpen: prevState?.treeState?.cachedOpen || {
      [CHART_TABS.WORK]: {},
      [CHART_TABS.RESOURCES]: {},
      [CHART_TABS.MATERIALS]: {},
      [CHART_TABS.EQUIPMENT]: {},
      [CHART_TABS.MIM]: {},
    },
    expander: {
      [CHART_TABS.WORK]: {
        days: false,
        period: false,
      },
    },
  },
  filters: initChartTreeFilters(),
  relations: {
    hash: -1,
    arrows: {
      [CHART_TABS.WORK]: {
        fromIntervalsById: {},
        fromGroupsById: {},
      },
    },
    draggedArrow: null,
    justAddedArrow: null,
  },
  checkpoinsMarks: initCheckpoinsMarks(),
  loaders: {
    blurred: false,
    blocking: true,
  },
  statuses:
    persistValues?.statuses && prevState?.statuses
      ? prevState.statuses
      : { data: CHART_STATUSES_DEFAULT_CONFIG, hasModifiedStatuses: false, rluColors: [] },
});

const chartInitialState: IChartState = createChartInitialState();

export default (state = chartInitialState, action: IChartAction): IChartState => {
  const { type, payload } = action;
  switch (type) {
    case "chart/ADD_TREE":
      const projectTrees = structuredClone(state.trees[payload.tab]?.children || []);
      const duplicateIndex = projectTrees.findIndex((t) => t.id == payload.tree.id);
      if (duplicateIndex > -1) {
        projectTrees.splice(payload.index, 1, payload.tree);
      } else {
        projectTrees.splice(payload.index, 0, payload.tree);
      }
      const tree = {
        ...state.trees[payload.tab],
        children: projectTrees,
      };
      patchRootTreeDatesDistance(tree);
      return {
        ...state,
        trees: {
          ...state.trees,
          [payload.tab]: tree,
        },
      };
    case "chart/UPDATE_TREE":
      return {
        ...state,
        trees: {
          ...state.trees,
          // @ts-ignore
          [payload.tab || state.tab]: updateTree(state.trees[payload.tab || state.tab], payload.params),
        },
      };
    case "chart/INCREMENT_PROJECT_SLICE_INDEX":
      return {
        ...state,
        projectSliceIndex: state.projectSliceIndex + 1,
      };
    case "chart/SET_PROJECT_IDS":
      return {
        ...state,
        projectIds: payload,
      };
    case "chart/SET_TAB":
      return {
        ...state,
        tab: payload,
      };
    case "chart/SET_VIEW_MODE":
      return {
        ...state,
        viewMode: payload,
      };
    case "chart/UPDATE_ACTION":
      return {
        ...state,
        actions: {
          ...state.actions,
          [payload.name]: payload.value,
        },
      };
    case "chart/UPDATE_TREE_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [payload.filter]: payload.value,
        },
      };
    case "chart/ADD_OPENED_TREE":
      const addedCachedOpen = {
        // @ts-ignore
        ...state.treeState.cachedOpen[state.tab],
        [payload]: true,
      };
      return {
        ...state,
        treeState: {
          ...state.treeState,
          cachedOpen: {
            ...state.treeState.cachedOpen,
            // @ts-ignore
            [state.tab]: addedCachedOpen,
          },
        },
      };
    case "chart/REMOVE_OPENED_TREE":
      // @ts-ignore
      const removedCachedOpen = { ...state.treeState.cachedOpen[state.tab] };
      delete removedCachedOpen[payload];
      return {
        ...state,
        treeState: {
          ...state.treeState,
          cachedOpen: {
            ...state.treeState.cachedOpen,
            // @ts-ignore
            [state.tab]: removedCachedOpen,
          },
        },
      };
    case "chart/UPDATE_TREE_EXPANDER":
      return {
        ...state,
        treeState: {
          ...state.treeState,
          expander: {
            ...state.treeState.expander,
            // @ts-ignore
            [payload.tab || state.tab]: {
              // @ts-ignore
              ...state.treeState.expander[payload.tab || state.tab],
              [payload.name]: payload.value,
            },
          },
        },
      };
    case "chart/SET_SCROLL_MARKERS":
      return {
        ...state,
        scrollState: {
          ...state.scrollState,
          markers: {
            ...state.scrollState.markers,
            [payload.year]: payload.markers,
          },
        },
      };
    case "chart/SET_TOUCHED_YEARS":
      return {
        ...state,
        scrollState: {
          ...state.scrollState,
          touchedYears: payload.touchedYears,
          markers: payload.options?.dropMarkers ? {} : state.scrollState.markers,
        },
      };
    case "chart/SET_YEAR":
      return {
        ...state,
        scrollState: {
          ...state.scrollState,
          year: payload,
        },
      };
    case "chart/SET_MONTH":
      return {
        ...state,
        scrollState: {
          ...state.scrollState,
          month: payload,
        },
      };
    case "chart/ADD_RELATIONS":
      return {
        ...state,
        relations: {
          ...state.relations,
          arrows: {
            ...state.relations.arrows,
            // @ts-ignore
            [payload.tab]: {
              // @ts-ignore
              ...state.relations.arrows[payload.tab],
              fromIntervalsById: _.merge(
                // @ts-ignore
                Object.assign({}, state.relations.arrows[payload.tab].fromIntervalsById),
                payload.fromIntervalsById
              ),
              fromGroupsById: _.merge(
                // @ts-ignore
                Object.assign({}, state.relations.arrows[payload.tab].fromGroupsById),
                payload.fromGroupsById
              ),
            },
          },
        },
      };
    case "chart/ADD_ARROW":
      return {
        ...state,
        relations: {
          ...state.relations,
          arrows: {
            ...state.relations.arrows,
            // @ts-ignore
            [payload.tab || state.tab]: {
              // @ts-ignore
              ...state.relations.arrows[payload.tab || state.tab],
              fromIntervalsById: payload.arrow.from_interval
                ? {
                    // @ts-ignore
                    ...state.relations.arrows[payload.tab || state.tab]?.fromIntervalsById,
                    [payload.arrow.from_interval]: (
                      state.relations.arrows[
                        // @ts-ignore
                        payload.tab || state.tab
                      ]?.fromIntervalsById[payload.arrow.from_interval] || []
                    ).concat(payload.arrow),
                  }
                : // @ts-ignore
                  state.relations.arrows[payload.tab || state.tab]?.fromIntervalsById,
              fromGroupsById: payload.arrow.from_group
                ? {
                    // @ts-ignore
                    ...state.relations.arrows[payload.tab || state.tab]?.fromGroupsById,
                    [payload.arrow.from_group]: (
                      state.relations.arrows[
                        // @ts-ignore
                        payload.tab || state.tab
                      ]?.fromGroupsById[payload.arrow.from_group] || []
                    ).concat(payload.arrow),
                  }
                : // @ts-ignore
                  state.relations.arrows[payload.tab || state.tab]?.fromGroupsById,
            },
          },
        },
      };
    case "chart/DELETE_ARROW":
      return {
        ...state,
        relations: {
          ...state.relations,
          arrows: {
            ...state.relations.arrows,
            // @ts-ignore
            [payload.tab || state.tab]: {
              // @ts-ignore
              ...state.relations.arrows[payload.tab || state.tab],
              fromIntervalsById: payload.arrow.from_interval
                ? {
                    // @ts-ignore
                    ...state.relations.arrows[payload.tab || state.tab]?.fromIntervalsById,
                    // @ts-ignore
                    [payload.arrow.from_interval]: state.relations.arrows[payload.tab || state.tab]?.fromIntervalsById[
                      payload.arrow.from_interval
                    ]?.filter(
                      // @ts-ignore
                      (a) => a.id !== payload.arrow.id
                    ),
                  }
                : // @ts-ignore
                  state.relations.arrows[payload.tab || state.tab]?.fromIntervalsById,
              fromGroupsById: payload.arrow.from_group
                ? {
                    // @ts-ignore
                    ...state.relations.arrows[payload.tab || state.tab]?.fromGroupsById,
                    // @ts-ignore
                    [payload.arrow.from_group]: state.relations.arrows[payload.tab || state.tab]?.fromGroupsById[
                      payload.arrow.from_group
                    ]?.filter(
                      // @ts-ignore
                      (a) => a.id !== payload.arrow.id
                    ),
                  }
                : // @ts-ignore
                  state.relations.arrows[payload.tab || state.tab]?.fromGroupsById,
            },
          },
        },
      };
    case "chart/UPDATE_ARROW":
      return {
        ...state,
        relations: {
          ...state.relations,
          arrows: {
            ...state.relations.arrows,
            // @ts-ignore
            [payload.tab || state.tab]: {
              // @ts-ignore
              ...state.relations.arrows[payload.tab || state.tab],
              fromIntervalsById: payload.arrow.from_interval
                ? {
                    // @ts-ignore
                    ...state.relations.arrows[payload.tab || state.tab]?.fromIntervalsById,
                    // @ts-ignore
                    [payload.arrow.from_interval]: state.relations.arrows[payload.tab || state.tab]?.fromIntervalsById[
                      payload.arrow.from_interval
                    ]?.map(
                      // @ts-ignore
                      (a) => (a.id === payload.arrow.id ? Object.assign(a, payload.data) : a)
                    ),
                  }
                : // @ts-ignore
                  state.relations.arrows[payload.tab || state.tab]?.fromIntervalsById,
              fromGroupsById: payload.arrow.from_group
                ? {
                    // @ts-ignore
                    ...state.relations.arrows[payload.tab || state.tab]?.fromGroupsById,
                    // @ts-ignore
                    [payload.arrow.from_group]: state.relations.arrows[payload.tab || state.tab]?.fromGroupsById[
                      payload.arrow.from_group
                    ]?.map(
                      // @ts-ignore
                      (a) => (a.id === payload.arrow.id ? payload.data : a)
                    ),
                  }
                : // @ts-ignore
                  state.relations.arrows[payload.tab || state.tab]?.fromGroupsById,
            },
          },
        },
      };
    case "chart/ADD_CHECKPOINTS_MARKS":
      return {
        ...state,
        checkpoinsMarks: {
          days: (() => {
            const newMarks = { ...state.checkpoinsMarks.days };
            for (const day in payload.days) {
              newMarks[day] = (newMarks[day] || 0) + (payload.days[day] || 0);
            }
            return newMarks;
          })(),
          yearWeeks: (() => {
            const newMarks = { ...state.checkpoinsMarks.yearWeeks };
            for (const week in payload.yearWeeks) {
              newMarks[week] = (newMarks[week] || 0) + (payload.yearWeeks[week] || 0);
            }
            return newMarks;
          })(),
        },
      };
    case "chart/SET_DRAGGED_ARROW":
      return {
        ...state,
        relations: {
          ...state.relations,
          draggedArrow: payload,
        },
      };
    case "chart/SET_JUST_ADDED_ARROW":
      return {
        ...state,
        relations: {
          ...state.relations,
          justAddedArrow: payload,
        },
      };
    case "chart/SET_DRAGGED_ARROW_RELATION_TYPE":
      if (!state.relations.draggedArrow) return state;
      return {
        ...state,
        relations: {
          ...state.relations,
          draggedArrow: { ...state.relations.draggedArrow, relationType: payload },
        },
      };
    case "chart/SET_ARROW_HASH":
      return {
        ...state,
        relations: {
          ...state.relations,
          hash: payload,
        },
      };
    case "chart/SET_IS_LOADED_INTERVALS_BY_YEAR":
      return {
        ...state,
        loadedIntervalsByYears: {
          ...state.loadedIntervalsByYears,
          [payload.tab]: {
            ...state.loadedIntervalsByYears[payload.tab],
            [payload.projectId]: {
              ...(state.loadedIntervalsByYears[payload.tab]?.[payload.projectId] || {}),
              [payload.year]: payload.isLoaded,
            },
          },
        },
      };
    case "chart/UPDATE_STATUSES":
      return {
        ...state,
        statuses: {
          ...state.statuses,
          data: {
            ...state.statuses.data,
            [payload.tab || state.tab!]: payload.statuses,
          },
        },
      };
    case "chart/SET_HAS_MODIFIED_STATUSES":
      return {
        ...state,
        statuses: {
          ...state.statuses,
          hasModifiedStatuses: payload,
        },
      };
    case "chart/STATUSES_PUSH_RLU_COLOR":
      let newRluColors = state.statuses.rluColors.slice();
      newRluColors.unshift(payload);
      newRluColors = Array.from(new Set(newRluColors));
      if (newRluColors.length > CHART_MAX_LRU_COLORS) {
        newRluColors = newRluColors.slice(0, CHART_MAX_LRU_COLORS);
      }
      return {
        ...state,
        statuses: {
          ...state.statuses,
          rluColors: newRluColors,
        },
      };
    case "chart/SET_LOADER":
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [payload.loader]: payload.value,
        },
      };
    case "chart/UPDATE_HASH":
      return {
        ...state,
        hash: payload,
      };
    case "chart/PATCH_STATE":
      return Object.assign(state, payload);
    case "chart/DROP":
      return createChartInitialState(state, payload);
    default:
      return state;
  }
};
