import { CHART_TABS, ChartActions, ChartSatusConfig } from "redux/modules/common/chart/types";

import { xarrowPropsType } from "react-xarrows";

export const CHART_LOADING_MESSAGES = [
  "Сверяем даты планов",
  "Формируем отрезки на графике",
  "Связываем работы",
  "Заргужаем последние изменения",
  "Обновляем планы",
];

export enum DAYS_ABBRS {
  "ПН",
  "ВТ",
  "СР",
  "ЧТ",
  "ПТ",
  "СБ",
  "ВС",
}

export const CHART_EXTRA_YEARS = 1;

export enum CHART_TREE_LVL {
  ROOT,
  PROJECT,
  LSR,
  SECTION,
  EXPENDITURE,
}

export enum CHART_ARROW_COLORS {
  HIGHLIGHT = "#7061ea",
  DISPLAY_NON_INTERACTIVE = "#9AD9FF",
  DISPLAY_INTERACTIVE = "#707070",
  CRITICAL = "#FF0000",
}

export const CHART_ARROW_CONFIG: Pick<
  xarrowPropsType,
  | "lineColor"
  | "headColor"
  | "strokeWidth"
  | "headSize"
  | "path"
  | "startAnchor"
  | "endAnchor"
  | "showTail"
  | "zIndex"
  | "gridBreak"
  | "arrowBodyProps"
  | "_extendSVGcanvas"
  | "gridRadius"
> = {
  lineColor: CHART_ARROW_COLORS.DISPLAY_INTERACTIVE,
  headColor: CHART_ARROW_COLORS.DISPLAY_INTERACTIVE,
  strokeWidth: 1.5,
  headSize: 4,
  path: "grid",
  gridBreak: "0%20",
  gridRadius: 10,
  arrowBodyProps: {
    strokeLinejoin: "round",
  },
  startAnchor: "right",
  endAnchor: "left",
  showTail: false,
  zIndex: 0,
  _extendSVGcanvas: 30,
};

export const PROJECTS_BUCKET_SIZE = 10;

export const CHART_ACTIONS_VISIBILITY_BY_TAB: Record<CHART_TABS, Partial<Record<ChartActions, boolean>>> = {
  [CHART_TABS.WORK]: {
    [ChartActions.linking_editing_enabled]: true,
    [ChartActions.plans_editing_enabled]: true,
    [ChartActions.show_expenditures_in_tree]: true,
  },
  [CHART_TABS.RESOURCES]: {
    [ChartActions.show_expenditures_in_tree]: true,
  },
  [CHART_TABS.MATERIALS]: {
    [ChartActions.show_expenditures_in_tree]: true,
  },
  [CHART_TABS.EQUIPMENT]: {
    [ChartActions.show_expenditures_in_tree]: true,
  },
  [CHART_TABS.MIM]: {
    [ChartActions.show_expenditures_in_tree]: true,
  },
};

const chartResourcesDefaultStatuses: ChartSatusConfig[CHART_TABS.RESOURCES] = {
  plans: {
    title: "Запланирован",
    color: "#BABABA",
  },
  ordered: {
    title: "Заказан",
    color: "#AF9AD0",
  },
  on_stock: {
    title: "На складе",
    color: "#9870D0",
  },
  issued: {
    title: "Выдан",
    color: "#9EDDDB",
  },
  accepted: {
    title: "Принят",
    color: "#6FC79B",
  },
  todo: {
    title: "К оплате",
    color: "#4FB1EB",
  },
  to_pay: {
    title: "Оплачен",
    color: "#6fc79b",
  },
};

export const CHART_STATUSES_DEFAULT_CONFIG: ChartSatusConfig = {
  [CHART_TABS.WORK]: {
    plan: {
      title: "План",
      color: "#DEDEDE",
    },
    completed: {
      title: "Выполнено",
      color: "#C888FC",
    },
    accepted: {
      title: "Принято",
      color: "#D5C9DF",
    },
    todo: {
      title: "К сдаче",
      color: "#4FB1EB",
    },
    to_pay: {
      title: "К оплате",
      color: "#6FC79B",
    },
  },
  [CHART_TABS.RESOURCES]: chartResourcesDefaultStatuses,
  [CHART_TABS.MIM]: chartResourcesDefaultStatuses,
  [CHART_TABS.MATERIALS]: chartResourcesDefaultStatuses,
  [CHART_TABS.EQUIPMENT]: chartResourcesDefaultStatuses,
};

export const CHART_MAX_LRU_COLORS = 5;

export const CHART_STATUSES_COLOR_PALETTE = [
  "#FF7170",
  "#FFBA5A",
  "#FFEE93",
  "#6AFA7E",
  "#4EA5FF",
  "#FF00CE",
  "#FF7600",
  "#FFD800",
  "#00FF9D",
  "#006CFF",
];
